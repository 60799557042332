angular.module 'sdbgui'
.factory 'selectizeService', ['slashDB', (slashdb) ->
  new class SelectizeService
    userSelectizeConfig: {
      valueField: 'user_id',
      labelField: 'user_id',
      searchField: ['user_id'],
      sortField: 'user_id',
      create: (input) ->
        return {'user_id': input}
      ,
      maxItems: null,
      delimiter: ',',
      plugins: ['remove_button'],
      closeAfterSelect: true,
      hideSelected: true,
    }

    constructor: ->
      @userOptions = []

    updateUserOptions: (configData) ->
      @userOptions.length = 0

      userOptions = [slashdb.settings.user]
      if configData?.query_id or configData?.db_id
        userOptions = userOptions.concat(configData.read).concat(configData.write).concat(configData.execute)

      if configData?.user_id
        userOptions = userOptions.concat(configData.view).concat(configData.edit).concat([configData.creator])

      slashdb.get '/userdef.json', false, {}, false
      .then (response) =>
        for user in Object.values(response.data)
          if user.user_id != 'admin'
            @userOptions.push {'user_id': user.user_id}
      .finally =>
        userOptions = Array.from(new Set(userOptions))
        for user_id in userOptions
          if user_id != 'admin'
            @userOptions.push {'user_id': user_id}
]